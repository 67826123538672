import React, { useState } from "react";

const CardPartner = ({ partner }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      key={partner?._id}
      className="relative border overflow-hidden rounded-md"
    >
      <div className="">
        <div>
          <img
            src={`https://server.gocleanix.com/public/uploads/images/${partner.image}`}
            alt=""
          />
        </div>
      </div>
      <div className="p-4">
        <h1 className="text-lg font-semibold text-center">{partner.name}</h1>
        <h4 className="text-sm text-center">{partner.address}</h4>
      </div>
      <div
        className={`absolute bottom-0 left-0 w-full bg-[#00000093] transition-all ease-linear duration-300 flex justify-center items-center overflow-hidden ${
          hover ? "h-full" : "h-0"
        }`}
      >
        <div className="text-white">
          <div className="p-4 text-center">
            <h4 className="text-md mb-1">
              Email:{" "}
              <a
                href={`mailto:${partner.email}`}
                target="_blank"
                rel="noreferrer"
              >
                {partner.email}
              </a>
            </h4>
            <h4 className="text-md mb-1">
              Phone:
              <a
                href={`tel:+${partner.phoneNo}`}
                target="_blank"
                rel="noreferrer"
              >
                {partner.phoneNo}
              </a>
            </h4>
            <h5 className="text-md">
              <a href={partner.website} target="_blank" rel="noreferrer">
                {partner.website}
              </a>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPartner;
