import React, { useEffect, useState } from "react";
import CardTableCustomers from "../../components/Cards/CardTableCustomers";

const Customers = () => {
  return (
    <>
      <CardTableCustomers />
    </>
  );
};

export default Customers;
