import React from "react";
import CardTableOrders from "../../components/Cards/CardTableOrders";

const Orders = () => {
  return (
    <>
      <CardTableOrders />
    </>
  );
};

export default Orders;
