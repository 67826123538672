import React, { useEffect, useState } from "react";

import CardTableReviews from "../../components/Cards/CardTableReviews";

const Reviews = () => {
  return (
    <>
      <CardTableReviews />
    </>
  );
};

export default Reviews;
