import { RouterProvider } from "react-router-dom";
import "./App.css";
import routes from "./routes/routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GlobalStoreProvider } from "./store/GlobalStoreContext";
function App() {
  return (
    <GlobalStoreProvider>
      <div className="App">
        <RouterProvider router={routes}></RouterProvider>
        <ToastContainer />
      </div>
    </GlobalStoreProvider>
  );
}

export default App;
