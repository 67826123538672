import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useModal from "../../hooks/useModal";

// components

import DropdownTableCustomer from "../Dropdowns/DropdownTableCustomer";
import ModalAddCustomer from "../Modals/ModalAddCustomer";
import TableLoader from "../Loader/TableLoader";
import ButtonRefresh from "../Buttons/ButtonRefresh";
import { FaCircle, FaUser } from "react-icons/fa";
import { useGlobalStore } from "../../store/GlobalStoreContext";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import {
  FETCH_CUSTOMERS,
  SET_ERROR,
  SET_LOADING,
} from "../../store/actionTypes";
import CardTableError from "./CardTableError";
import DropdownTableOrder from "../Dropdowns/DropdownTableOrder";
import ModalViewItem from "../Modals/ModalViewItem";

export default function CardTableOrders({ color }) {
  const [orders, setOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [googleUser, googleLoading, googleRrror] = useAuthState(auth);
  const { modalIsOpen, openModal, closeModal } = useModal(false);
  const {
    modalIsOpen: modalIsOpen1,
    openModal: openModal1,
    closeModal: closeModal1,
  } = useModal(false);
  const fetchData = async (url) => {
    setLoading(true);
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.success) {
        setOrders(data.data);
      } else {
        setError(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData("https://server.gocleanix.com/api/v1/orders");
  }, []);
  const handleRefresh = () => {
    fetchData("https://server.gocleanix.com/api/v1/orders");
    // dispatch({ type: SET_LOADING, target: "customers" });
    // fetch(`https://api.gocleanix.com/customers?email=${googleUser.email}`, {
    //   method: "GET",
    //   headers: {
    //     authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //   },
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     dispatch({ type: FETCH_CUSTOMERS, payload: data });
    //   })
    //   .catch((error) =>
    //     dispatch({ type: SET_ERROR, target: "customers", payload: error })
    //   );
  };
  if (loading) {
    return <TableLoader />;
  }
  if (error) {
    return <CardTableError error={error} />;
  }

  let content = (
    <>
      {orders
        ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .map((order) => (
          <tr key={order._id} className="font-semibold border-b">
            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {order?.phoneNo}
            </td>
            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {order?.serviceName}
            </td>
            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {order?.address}
            </td>
            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {order?.bookingDate}
            </td>
            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              {order.status ? (
                <>
                  <FaCircle className="text-green-500 mr-2 inline" />
                  <span className="font-medium">seen</span>
                </>
              ) : (
                <>
                  <FaCircle className="text-red-500 mr-2 inline" />
                  <span className="font-medium">unseen</span>
                </>
              )}
            </td>
            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right relative">
              <DropdownTableOrder
                orders={orders}
                setOrders={setOrders}
                setOrderDetails={setOrderDetails}
                handleRefresh={handleRefresh}
                order={order}
                googleUser={googleUser}
                openModal1={openModal1}
              />
            </td>
          </tr>
        ))}
    </>
  );
  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                Orders
              </h3>
            </div>
            <div>
              <ButtonRefresh onClick={handleRefresh} />
              {/* <button
                onClick={() => openModal()}
                className="bg-tertiary px-5 py-1 font-medium text-md rounded"
              >
                Add New Customer
              </button> */}
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Phone
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Service Name
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left" +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Address
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Booking Date
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Status
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                ></th>
              </tr>
            </thead>
            <tbody>{orders?.loading ? <TableLoader /> : content}</tbody>
          </table>
        </div>
      </div>
      <ModalAddCustomer isOpen={modalIsOpen} closeModal={closeModal} />
      <ModalViewItem isOpen={modalIsOpen1} closeModal={closeModal1}>
        <div>
          <h4 className="mb-1">
            Customer Name:{" "}
            <span className="text-md font-semibold">
              {orderDetails?.fullName}
            </span>
          </h4>
          <h4 className="mb-1">
            Order Time :{" "}
            <span className="text-md font-semibold">
              {orderDetails?.createdAt?.slice(0, 16)}
            </span>
          </h4>
          <h4 className="mb-1">
            Email :{" "}
            <span className="text-md font-semibold">{orderDetails?.email}</span>
          </h4>
        </div>
      </ModalViewItem>
    </>
  );
}

CardTableOrders.defaultProps = {
  color: "light",
};

CardTableOrders.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
