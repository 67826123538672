import React from "react";

// components
import CardTableUsers from "../../components/Cards/CardTableUsers";

const Users = () => {
  return (
    <>
      <CardTableUsers />
    </>
  );
};

export default Users;
