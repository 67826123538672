import React, { useEffect, useState } from "react";
import useModal from "../../hooks/useModal";

// components
import DropdownShowcaseCard from "../../components/Dropdowns/DropdownShowcaseCard";
import CardShowcase from "../../components/Cards/CardShowcase";
import ModalAddShowcase from "../../components/Modals/ModalAddShowcase";
import ButtonRefresh from "../../components/Buttons/ButtonRefresh";
import { useGlobalStore } from "../../store/GlobalStoreContext";
import ShowcaseLoader from "../../components/Loader/ShowcaseLoader";
import CardTableError from "../../components/Cards/CardTableError";
import {
  FETCH_SHOWCASES,
  SET_ERROR,
  SET_LOADING,
} from "../../store/actionTypes";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import ModalShowShowcase from "../../components/Modals/ModalShowShowcase";
import BackdropLoader from "../../components/Loader/BackdropLoader";

const AdminShowcase = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [googleUser, googleLoading, googleError] = useAuthState(auth);
  const { modalIsOpen, closeModal, openModal } = useModal(false);
  const {
    modalIsOpen: modalIsOpenView,
    closeModal: closeModalView,
    openModal: openModalView,
  } = useModal(false);
  const {
    modalIsOpen: loaderModalIsOpen,
    closeModal: closeLoaderModal,
    openModal: openLoaderModal,
  } = useModal(false);
  const [viewShowcaseImg, setViewShowcaseImg] = useState(null);
  const fetchData = async (url) => {
    setLoading(true);
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.success) {
        setProjects(data.data);
      } else {
        setError(data.message);
      }
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };
  const handleRefresh = async () => {
    fetchData("https://server.gocleanix.com/api/v1/projects");
  };
  useEffect(() => {
    fetchData("https://server.gocleanix.com/api/v1/projects");
  }, []);
  if (loading) {
    return <ShowcaseLoader />;
  }
  if (error) {
    return <CardTableError error={error} />;
  }
  return (
    <>
      <div>
        <div className="mb-5 flex justify-center">
          <ButtonRefresh onClick={handleRefresh} />
          <button
            onClick={() => openModal()}
            className="inline-block px-4 py-2 bg-tertiary uppercase font-semibold"
          >
            add new project
          </button>
        </div>
        <div className="grid lg:md:grid-cols-3 grid-cols-1 gap-8">
          {projects.map((showcase) => (
            <div key={showcase?._id} className="relative">
              <CardShowcase showcase={showcase} />
              <DropdownShowcaseCard
                projects={projects}
                setProjects={setProjects}
                showcase={showcase}
                googleUser={googleUser}
                setViewShowcaseImg={setViewShowcaseImg}
                openModalView={openModalView}
              />
            </div>
          ))}
        </div>
      </div>
      <ModalAddShowcase
        projects={projects}
        setProjects={setProjects}
        isOpen={modalIsOpen}
        closeModal={closeModal}
        googleUser={googleUser}
        openLoaderModal={openLoaderModal}
        closeLoaderModal={closeLoaderModal}
      />
      <ModalShowShowcase
        isOpen={modalIsOpenView}
        closeModal={closeModalView}
        img={viewShowcaseImg}
      />
      <BackdropLoader
        isOpen={loaderModalIsOpen}
        closeModal={closeLoaderModal}
      />
    </>
  );
};

export default AdminShowcase;
